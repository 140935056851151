import { Auth } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { orderBy } from 'lodash';
import {
  ICreateUserPayload,
  IHubUser,
  IHubUserPayload,
  INewInviteUser,
  ITeam,
  ITeamDetail,
  IUser
} from '../../../interfaces';
import { prodBaseUrlV2 } from '../../config';
import { apigClient } from '../aws';

export const mapUserPayloadToUser = (payload: IHubUserPayload): IHubUser => {
  const brandEntitlement = payload.entitlements?.find(
    (e) => e.entitlementType === 'BRAND_ID'
  );
  const storeIdEntitlement = payload.entitlements?.find(
    (e) => e.entitlementType === 'STORE_ID'
  );
  const associatedStoreEntitlement = payload.entitlements?.find(
    (e) => e.entitlementType === 'ASSOCIATED_STORE_ID'
  );

  return {
    id: payload.id,
    email: payload?.email,
    name: payload.alias,
    brandId: brandEntitlement?.uuid,
    storeId: storeIdEntitlement?.uuid,
    associatedStoreId: associatedStoreEntitlement?.uuid,
    language: payload.language,
    alias: payload.alias,
    entitlements: payload.entitlements
  };
};

const loadUserById = async (id: string): Promise<IHubUser> => {
  const client = await apigClient();
  const path = `/users/v3/web/${encodeURIComponent(id)}`;
  // interface ApiResponse {
  //   data: {
  //     body: string;
  //   };
  // }
  const userResponse: AxiosResponse<any> = await client.invokeApi(
    {},
    path,
    'GET',
    {}
  );
  // const parsedBody = JSON.parse(userResponse.data.body);
  
  return mapUserPayloadToUser(userResponse.data);
};

export const getUser = async (): Promise<IHubUser> => {
 // console.log('id');

  // the initial loaded identity from the cognito pool. For old users it will be different and we need to swap
  const id = localStorage.getItem('IDENTITY_ID') as string;
  const userData = await loadUserById(id);

  // swap the itentity based on the user.id
  const userId = userData.id;
  localStorage.setItem('IDENTITY_ID',userId);

  if (!userData.brandId) {
    return userData;
  }
  const brandData = await loadUserById(userData.brandId);
  return {
    ...userData,
    brandName: brandData.name
  };
};

export const getListUsers = async (storeId: string): Promise<IUser[]> => {
  try {
    const path = `/users/v4/web/byStoreId/${storeId}`;
    const client = await apigClient();
    const usersResponse = await client.invokeApi({}, path, 'GET', {});
    return usersResponse.data;
  } catch (error) {
    console.log('fail to get list users');
    console.log(error);
  }
};

export const confirmSignupAdmin = async (
  username: string,
  password: string
): Promise<any> => {
  try {
    const path = `/users/v3/web/confirmSignUp`;
    const client = await apigClient();
    const confirmResponse = await client.invokeApi(
      {},
      path,
      'POST',
      {},
      { username, password }
    );
    return confirmResponse.data;
  } catch (error) {
    console.log('failed to confirm user: ', username);
    console.log(error);
  }
};

export const createNewUser = async (newUser: ICreateUserPayload) => {
  try {
    const path = `/users/v3/web/createNewUser`;
    const client = await apigClient();
    const createUserResponse = await client.invokeApi(
      {},
      path,
      'POST',
      {},
      newUser
    );
    return createUserResponse.data;
  } catch (error) {
    console.log('failed to confirm user: ', newUser.username);
    console.log(error.response.data);
    return Promise.reject(error?.response?.data);
  }
};
export const inviteNewUsers = async (newUsers: INewInviteUser[]) => {
  try {
    const onboardingUrl =
      'https://1767u9oiwk.execute-api.ap-southeast-1.amazonaws.com/prod';
    const path = `/onboarding/v1/inviteUsers`;
    const client = await apigClient(onboardingUrl);
    const createUserResponse = await client.invokeApi(
      {},
      path,
      'POST',
      {},
      newUsers
    );
    return createUserResponse.data;
  } catch (error) {
    console.error(error.response?.data);
    return Promise.reject(error?.response?.data);
  }
};

export const completeNewPassword = async (
  username: string,
  password: string,
  newPassword
) => {
  try {
    const loginResponse = await Auth.signIn(username, password);
    return Auth.completeNewPassword(loginResponse, newPassword);
  } catch (error) {
    return error;
  }
};

export const deleteUserById = async (userId: string) => {
  const path = `/users/v3/web/deleteById`;
  const client = await apigClient();
  const deleteUserResponse = await client.invokeApi(
    {},
    path,
    'DELETE',
    {},
    userId
  );
  return deleteUserResponse.data;
};
export const createTeam = async (team: ITeam) => {
  try {
    const path = `/teams/v1/`;
    const client = await apigClient(prodBaseUrlV2);
    const _team = { ...team, createdAt: new Date(), modifiedAt: new Date() };
    const usersResponse = await client.invokeApi({}, path, 'POST', {}, _team);
    return usersResponse.data;
  } catch (error) {
    console.log('fail to get list users');
    console.log(error);
  }
};
export const updateTeam = async (team: ITeam) => {
  try {
    const path = `/teams/v1/`;
    const client = await apigClient(prodBaseUrlV2);
    const usersResponse = await client.invokeApi({}, path, 'POST', {}, team);
    return usersResponse.data;
  } catch (error) {
    console.log('fail to get list users');
    console.log(error);
  }
};
export const deleteTeamByID = async (teamId: string) => {
  try {
    const path = `/teams/v1/${teamId}`;
    const client = await apigClient(prodBaseUrlV2);
    const usersResponse = await client.invokeApi({}, path, 'DELETE', {});
    return usersResponse.data;
  } catch (error) {
    console.log('fail to delete Team');
    console.log(error);
  }
};
export const getListUsersByBrand = async (
  brandId: string
): Promise<IUser[]> => {
  try {
    const path = `/users/v4/web/byBrandId/${brandId}`;
    const client = await apigClient();
    const usersResponse = await client.invokeApi({}, path, 'GET', {});
    return usersResponse.data;
  } catch (error) {
    console.log('fail to get list users');
    console.log(error);
  }
};

export const getAllTeams = async (brandId: string): Promise<ITeam[]> => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/teams/v1/byBrand/${brandId}`;
  const response = await client.invokeApi({}, path, 'GET', {});
  return orderBy(response.data.teams, [(team) => team.name?.toLowerCase()], ['asc']);
};

export const getListTeams = async (
  brandId: string,
  keywords: string,
  startIndex: number,
  batchSize?: number
) => {
  const size = batchSize || 20;
  try {
    const path = `/teamsearch/v1`;
    const search = {
      keywords: keywords,
      size: size,
      startIndex: startIndex,
      queryString: `(and brand: '${brandId}')`
    };
    const client = await apigClient(prodBaseUrlV2);
    const usersResponse = await client.invokeApi({}, path, 'POST', {}, search);
    return usersResponse.data;
  } catch (error) {
    console.log('fail to get list users');
    console.log(error);
  }
};

export const getTeam = async (teamId: string): Promise<ITeamDetail> => {
  try {
    const client = await apigClient(prodBaseUrlV2);
    const path = `/teams/v1/${teamId}`;
    const usersResponse = await client.invokeApi({}, path, 'GET', {});
    return usersResponse.data;
  } catch (error) {
    console.log('fail to get team');
    console.log(error);
  }
};

export const getUserTeams = async (
  userId: string,
  brandId: string,
  startIndex: number,
  batchSize: number
) => {
  const path = `/teamsearch/v1`;
  const client = await apigClient(prodBaseUrlV2);
  const response = await client.invokeApi(
    {},
    path,
    'POST',
    {},
    {
      keywords: '',
      queryString: `(and members:'${userId}' brand: '${brandId}')`,
      startIndex,
      size: batchSize
    }
  );
  return response.data;
};
